/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import Cards from 'react-credit-cards';
import { isValid } from '@fnando/cpf';
import { isValid as isValidCnpj } from '@fnando/cnpj';
import * as EmailValidator from 'email-validator';
import { useNavigate } from 'react-router-dom';
import { Product, ProductType } from '.';
import DefaultInput from '../../components/Input/Input';
import DefaultOption from '../../components/Option';
import CEPFinder from '../../helpers/findAddressByCep';
import {
  maskCardExpiration,
  maskCep,
  maskCNPJ,
  maskCPF,
  maskDate,
  maskNumber,
  maskPhone,
  unmaskData,
} from '../../helpers/maskInputs.js';
import statesOfBrazil from '../../helpers/statesOfBrazil';
import 'react-credit-cards/es/styles-compiled.css';
import { validateCnpj, validateCpf } from '../../helpers/validateCpf';
import api from '../../config/axios';
import emitents from '../../helpers/rgEmitents';
import ModalResponse from './ModalResponse';
import LoadingChildren from './ModalResponse/LoadingChildren';
import ErrorChildren from './ModalResponse/ErrorChildren';
import isValidDate, { isMajority } from '../../helpers/validateDate';
import validatePhone from '../../helpers/validatePhone';
import ValidatorErrors from './ModalResponse/ValidatorErrors';
import CardOk from './ModalResponse/CardSuccess';
import BoletoOk from './ModalResponse/BoletoSuccess';
import capitalizeFirstLetter, { formatDate, formatEnterpriseSize } from '../../helpers/texts';
import ModalTerms from './ModalUseTerms';
import PixSuccess from './ModalResponse/PixSuccess';

interface IFormsProps {
  selectedProduct: Product;
  backToProduct: () => void;
}

type BoletoResponse = {
  Carnes: {
    pdf: string;
  }[];
};

type ClientProps = {
  name: string;
  cpf: string;
  rg: string;
  emitent: string;
  enterpriseName: string;
  enterpriseFantasyName: string;
  enterpriseCnpj: string;
  email: string;
  numberOfHolders: number;
  numberOfDependents: number;
  tel: string;
  phone: string;
  birthday: string;
  sex: string;
  addressCity: string;
  addressNeighborhood: string;
  addressStreet: string;
  addressCep: string;
  addressComplement: string;
  addressState: string;
  addressNumber: string;
  addressZipcode: string;
};

export default function Forms({ selectedProduct, backToProduct }: IFormsProps) {
  const navigate = useNavigate();
  const [client, setClient] = useState<ClientProps>({
    name: '',
    cpf: '',
    rg: '',
    emitent: 'SSP/MA',
    email: '',
    enterpriseCnpj: '',
    enterpriseFantasyName: '',
    enterpriseName: '',
    phone: '',
    tel: '',
    birthday: '',
    sex: 'Masculino',
    numberOfHolders: 0,
    numberOfDependents: 0,
    addressCep: '',
    addressCity: 'Imperatriz',
    addressNeighborhood: '',
    addressState: 'MA',
    addressStreet: '',
    addressComplement: '',
    addressNumber: '',
    addressZipcode: '',
  });
  const [paymentMethod, setPaymentMethod] = useState<
    'CARTÃO' | 'BOLETO-12X' | 'BOLETO-3X' | 'BOLETO-6X' | 'PIX'
  >('CARTÃO');
  const [paymentMethodCard, setPaymentMethodcard] = useState({
    cardNumber: '',
    cardHolder: '',
    cardExpiration: '',
    cardCvv: '',
  });
  const [fetchCepLoading, setFetchCepLoading] = useState(false);
  const [canFetchCep, setCanFetchCep] = useState(false);
  const [focusCvv, setFocusCvv] = useState(false);
  const [modalChild, setModalChild] = useState(<LoadingChildren />);
  const [terms, setTerms] = useState({
    termsOfUse: false,
    termsOfPolicy: false,
  });

  function calcValue() {
    return selectedProduct.pricePerHolder * 12;
    // if (selectedProduct.type === ProductType.INDIVIDUAL) {
    //   return (selectedProduct.pricePerHolder * 1 + selectedProduct.pricePerDependent * client.numberOfDependents) * 12;
    // }
    // return (selectedProduct.pricePerHolder * client.numberOfHolders + selectedProduct.pricePerDependent * client.numberOfDependents) * 12;
  }

  function validate() {
    const errors = [];
    if (selectedProduct.type === ProductType.INDIVIDUAL) {
      if (client.name.length === 0) {
        errors.push('O Nome é obrigatório');
      }
      if (!isValid(client.cpf)) {
        errors.push('CPF inválido');
      }
      if (client.rg.length < 5) {
        errors.push('RG inválido');
      }
      if (!isValidDate(client.birthday)) {
        errors.push('Data de nascimento inválida');
      }
      if (isMajority(client.birthday)) {
        errors.push('Cliente titular deverá ser maior de 18 anos');
      }
      if (!EmailValidator.validate(client.email)) {
        errors.push('Email inválido');
      }
      if (!validatePhone(unmaskData(client.phone))) {
        errors.push('Telefone inválido');
      }
      if (client.addressCep.length < '00000-000'.length) {
        errors.push('CEP inválido');
      }
      if (client.addressCity === '') {
        errors.push('Digite a cidade de moradia');
      }
      if (client.addressStreet === '') {
        errors.push('Digite o logradouro de moradia');
      }
      if (client.addressNeighborhood === '') {
        errors.push('Digite o bairro de moradia');
      }
      if (client.addressNumber === '') {
        errors.push('Digite o número residencial');
      }
      if (paymentMethod === 'CARTÃO') {
        if (paymentMethodCard.cardExpiration.length !== 7) {
          errors.push('Digite a validade no formato MM/AAAA exemplo: 01/2030');
        }
        if (
          paymentMethodCard.cardNumber === ''
          || paymentMethodCard.cardExpiration === ''
          || paymentMethodCard.cardCvv === ''
          || paymentMethodCard.cardHolder === ''
        ) {
          errors.push('Preencha os dados do cartão corretamente');
        }
      }
      if (!terms.termsOfUse) {
        errors.push('Você deve concordar com os Termos de Uso e Política de Privacidade');
      }
    } else {
      if (client.enterpriseName.length === 0) {
        errors.push('O Nome é obrigatório');
      }
      if (!isValidCnpj(client.enterpriseCnpj)) {
        errors.push('CNPJ inválido');
      }
      if (!EmailValidator.validate(client.email)) {
        errors.push('Email inválido');
      }
      if (!validatePhone(unmaskData(client.phone))) {
        errors.push('Telefone inválido');
      }
      if (client.addressCep.length < '00000-000'.length) {
        errors.push('CEP inválido');
      }
      if (client.addressCity === '') {
        errors.push('Digite a cidade de moradia');
      }
      if (client.addressStreet === '') {
        errors.push('Digite o logradouro de moradia');
      }
      if (client.addressNeighborhood === '') {
        errors.push('Digite o bairro de moradia');
      }
      if (client.addressNumber === '') {
        errors.push('Digite o número residencial');
      }
      if (client.numberOfHolders === 0) {
        errors.push('Deverá possuir ao menos um titular.');
      }
      if (paymentMethod === 'CARTÃO') {
        if (
          paymentMethodCard.cardNumber === ''
          || paymentMethodCard.cardExpiration === ''
          || paymentMethodCard.cardCvv === ''
          || paymentMethodCard.cardHolder === ''
        ) {
          errors.push('Preencha os dados do cartão corretamente');
        }
      }
      if (!terms.termsOfUse) {
        errors.push('Você deve concordar com os Termos de Uso e Política de Privacidade');
      }
    }
    return errors;
  }

  async function fetchCep() {
    document.getElementById('cep')?.addEventListener('focusout', async () => {
      if (client.addressCep.length > 9 && canFetchCep) {
        setCanFetchCep(false);
        setFetchCepLoading(true);
        const cep = await new CEPFinder(unmaskData(client.addressCep)).findCep();
        setFetchCepLoading(false);
        if (cep) {
          setClient({
            ...client,
            addressCity: cep.city,
            addressNeighborhood: cep.neighborhood,
            addressStreet: cep.street,
            addressState: cep.state,
          });
        }
      }
    });
  }

  async function fetchCNPJ() {
    try {
      if (
        client.enterpriseCnpj.length === '00.000.000/0000-00'.length
        && selectedProduct.modality === 'EMPRESARIAL'
      ) {
        const response = await api.get(`/receitaws/${unmaskData(client.enterpriseCnpj)}`);
        if (typeof response.data.uf !== 'undefined') {
          const clientData = {
            name: capitalizeFirstLetter(response.data.nome),
            nameFantasy: capitalizeFirstLetter(response.data.fantasia),
            addressComplement: capitalizeFirstLetter(response.data.complemento),
            addressState: response.data.uf,
            addressCity: capitalizeFirstLetter(response.data.municipio),
            addressStreet: capitalizeFirstLetter(response.data.logradouro),
            addressNeighborhood: capitalizeFirstLetter(response.data.bairro),
            addressNumber: response.data.numero,
            addressZipcode: response.data.cep,
            size: formatEnterpriseSize(response.data.porte),
            openingDate: formatDate(response.data.abertura),
            email: response.data.email,
            phone: response.data.telefone,
          };
          setClient({
            ...client,
            name: clientData.name,
            enterpriseName: clientData.name,
            enterpriseFantasyName: clientData.nameFantasy,
            addressComplement: clientData.addressComplement,
            addressState: clientData.addressState,
            addressCity: clientData.addressCity,
            addressStreet: clientData.addressStreet,
            addressNeighborhood: clientData.addressNeighborhood,
            addressNumber: clientData.addressNumber,
            addressZipcode: clientData.addressZipcode,
          });
        }
      }
    } catch (err: any) {
      console.log('Error at fetch CNPJ', err.response);
    }
  }

  async function sendEnterprise() {
    let method = '';

    if (paymentMethod === 'CARTÃO') {
      method = 'CARTAO';
    } else {
      method = paymentMethod;
    }
    const payload = {
      cnpj: unmaskData(client.enterpriseCnpj),
      name: client.enterpriseName,
      addressStreet: client.addressStreet,
      addressNumber: client.addressNumber,
      addressComplement: client.addressComplement,
      addressNeighborhood: client.addressNeighborhood,
      addressCity: client.addressCity,
      addressState: client.addressState,
      addressZipcode: unmaskData(client.addressCep),
      phone: unmaskData(client.phone),
      email: client.email,
      enableTelemedicine: false,
      numberOfDependents: client.numberOfDependents,
      numberOfHolders: client.numberOfHolders,
      nameFantasy: client.enterpriseFantasyName,
      method,
      cardNumber: paymentMethodCard.cardNumber,
      cardHolder: paymentMethodCard.cardHolder,
      cardCvv: paymentMethodCard.cardCvv,
      cardExpiration: `${paymentMethodCard.cardExpiration.split('/')[1]}-${paymentMethodCard.cardExpiration.split('/')[0]
      }`,
    };
    const response = await api.post('/sellweb/pj', { ...payload });
    if (paymentMethod === 'CARTÃO') {
      setModalChild(<CardOk email={client.email} />);
    } else if (
      paymentMethod === 'BOLETO-12X'
      || paymentMethod === 'BOLETO-3X'
      || paymentMethod === 'BOLETO-6X'
    ) {
      setModalChild(
        <BoletoOk
          email={client.email}
          link={response.data.Carnes ? response.data.Carnes[0].pdf || '' : ''}
        />,
      );
    } else {
      setModalChild(
        <PixSuccess
          reference={response.data.reference}
          image={response.data.image}
          page={response.data.page}
          qrCode={response.data.qrCode}
          email={client.email}
        />,
      );
    }
  }

  async function sendIndividual() {
    let method = '';

    if (paymentMethod === 'CARTÃO') {
      method = 'CARTAO';
    } else {
      method = paymentMethod;
    }
    const payload = {
      cpf: unmaskData(client.cpf),
      name: client.name,
      sex: client.sex === 'Masculino' ? 'm' : 'f',
      birthday: client.birthday,
      rg: client.rg,
      emitent: client.emitent,
      addressStreet: client.addressStreet,
      addressNumber: client.addressNumber,
      addressComplement: client.addressComplement,
      addressNeighborhood: client.addressNeighborhood,
      addressCity: client.addressCity,
      addressState: client.addressState,
      addressZipcode: unmaskData(client.addressCep),
      phone: unmaskData(client.phone),
      email: client.email,
      enableTelemedicine: false,
      numberOfDependents: client.numberOfDependents,
      method,
      cardNumber: paymentMethodCard.cardNumber,
      cardHolder: paymentMethodCard.cardHolder,
      cardCvv: paymentMethodCard.cardCvv,
      productId: selectedProduct.id,
      cardExpiration: `${paymentMethodCard.cardExpiration.split('/')[1]}-${paymentMethodCard.cardExpiration.split('/')[0]
      }`,
    };
    const response = await api.post('/sellweb/pf', { ...payload });
    if (paymentMethod === 'CARTÃO') {
      setModalChild(<CardOk email={client.email} />);
    } else if (
      paymentMethod === 'BOLETO-12X'
      || paymentMethod === 'BOLETO-3X'
      || paymentMethod === 'BOLETO-6X'
    ) {
      setModalChild(
        <BoletoOk
          email={client.email}
          link={response.data.Carnes ? response.data.Carnes[0].pdf || '' : ''}
        />,
      );
    } else {
      setModalChild(
        <PixSuccess
          reference={response.data.reference}
          image={response.data.image}
          page={response.data.page}
          qrCode={response.data.qrCode}
          email={client.email}
        />,
      );
    }
  }

  async function sendForm() {
    setModalChild(<LoadingChildren />);
    try {
      const validation = validate();
      if (validation.length > 0) {
        setModalChild(<ValidatorErrors errors={validation} />);
      } else if (selectedProduct.type === ProductType.INDIVIDUAL) {
        await sendIndividual();
      } else {
        await sendEnterprise();
      }
    } catch (err: any) {
      console.log(err);
      setModalChild(<ErrorChildren error={err.response.data} />);
      console.log(err.response.data);
    }
  }

  function resolvePaymentMethod() {
    if (paymentMethod === 'CARTÃO') {
      return 'CARTÃO DE CRÉDITO - 12 X';
    }
    if (paymentMethod === 'BOLETO-12X') {
      return 'CARNÊ BOLETO/PIX - 12 X';
    }
    if (paymentMethod === 'BOLETO-3X') {
      return 'CARNÊ BOLETO/PIX - 3 X';
    }
    if (paymentMethod === 'BOLETO-6X') {
      return 'CARNÊ BOLETO/PIX - 6 X';
    }
    return 'PIX - A VISTA';
  }

  useEffect(() => {
    if (client.addressCep.length > 9) {
      fetchCep();
    }
  }, [client]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCanFetchCep(true);
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, []);
  return (
    <>
      <div className="container lead-form">
        <div className="row">
          <div className="d-flex">
            <span className="material-icons-round">arrow_back</span>
            <button type="button" className="backward-btn" onClick={backToProduct}>
              <p>Selecionar produto</p>
            </button>
          </div>
        </div>
        <div className="row">
          <div className="select-product-header">
            {/* <h3>{selectedProduct.modality}</h3> */}
            <h3>{selectedProduct.name}</h3>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-lg-4 p-4">
            <div className="title-wrapper">
              <p className="form-title">
                Cadastro
                {' '}
                {`${selectedProduct.modality === 'INDIVIDUAL' ? '- Titular' : ''}`}
              </p>
              <span className="material-icons-round">expand_more</span>
            </div>
            <div className="subtitle">
              <p className="text-muted">
                Os seus dados serão utilizados apenas para realização de cadastro.
              </p>
            </div>
            <div className="input-container">
              {selectedProduct.type === ProductType.INDIVIDUAL && (
                <DefaultInput
                  id="name"
                  label="Nome completo *"
                  value={client.name}
                  error={client.name.length === 0 ? 'Campo obrigatório' : ''}
                  type="text"
                  onChange={(arg) => setClient({ ...client, name: arg })}
                  placeholder="Digite o nome"
                  loading={false}
                />
              )}
              {selectedProduct.type === ProductType.INDIVIDUAL && (
                <DefaultInput
                  id="cpf"
                  label="CPF *"
                  value={client.cpf}
                  error={!validateCpf(client.cpf) ? 'CPF inválido' : ''}
                  onChange={(arg) => setClient({ ...client, cpf: maskCPF(arg) })}
                  placeholder="Digite o CPF"
                  loading={false}
                />
              )}
              {selectedProduct.type === ProductType.INDIVIDUAL && (
                <DefaultInput
                  id="rg"
                  label="RG *"
                  value={client.rg}
                  error={client.rg.length < 5 ? 'Digite um RG válido' : ''}
                  onChange={(arg) => setClient({ ...client, rg: maskNumber(arg) })}
                  placeholder="Apenas números"
                  loading={false}
                />
              )}
              {selectedProduct.type === ProductType.INDIVIDUAL && (
                <DefaultOption
                  id="emitent"
                  label="Emitente do RG *"
                  value={client.emitent}
                  onChange={(arg) => setClient({ ...client, emitent: arg })}
                  placeholder="Órgao Emissor"
                  loading={false}
                  elements={[...emitents]}
                />
              )}
              <div className="mb-4" />
              {selectedProduct.type === ProductType.INDIVIDUAL && (
                <DefaultOption
                  id="sex"
                  label="Sexo *"
                  value={client.sex}
                  onChange={(arg) => setClient({ ...client, sex: arg })}
                  placeholder="Sexo"
                  loading={false}
                  elements={['Masculino', 'Feminino']}
                />
              )}
              {selectedProduct.type === ProductType.INDIVIDUAL && (
                <DefaultInput
                  id="birth"
                  label="Nascimento *"
                  value={client.birthday}
                  error={!isValidDate(client.birthday) ? 'Digite uma data válida' : ''}
                  onChange={(arg) => setClient({ ...client, birthday: maskDate(arg) })}
                  placeholder="DD-MM-AAAA"
                  loading={false}
                />
              )}
              {selectedProduct.type === ProductType.ENTERPRISE && (
                <DefaultInput
                  id="cnpj"
                  label="CNPJ *"
                  value={client.enterpriseCnpj}
                  error={!validateCnpj(client.enterpriseCnpj) ? 'CNPJ inválido' : ''}
                  onChange={(arg) => setClient({ ...client, enterpriseCnpj: maskCNPJ(arg) })}
                  placeholder="Digite o CNPJ"
                  focusOut={() => fetchCNPJ()}
                  loading={false}
                />
              )}
              {selectedProduct.type === ProductType.ENTERPRISE && (
                <DefaultInput
                  id="razao-social"
                  label="Razão social *"
                  value={client.enterpriseName}
                  error={client.enterpriseName === '' ? 'Campo obrigatório' : ''}
                  type="text"
                  onChange={(arg) => setClient({ ...client, enterpriseName: arg })}
                  placeholder="Ex: XPTO LTDA."
                  loading={false}
                />
              )}
              {selectedProduct.type === ProductType.ENTERPRISE && (
                <DefaultInput
                  id="name"
                  label="Nome fantasia *"
                  value={client.enterpriseFantasyName}
                  type="text"
                  error={client.enterpriseFantasyName === '' ? 'Campo obrigatório' : ''}
                  onChange={(arg) => setClient({ ...client, enterpriseFantasyName: arg })}
                  placeholder="Ex: XPTO Alimentos"
                  loading={false}
                />
              )}
              <DefaultInput
                id="email"
                label="email *"
                value={client.email}
                error={client.email === '' ? 'Campo obrigatório' : ''}
                onChange={(arg) => setClient({ ...client, email: arg })}
                placeholder="Digite o E-mail"
                loading={false}
              />
              <DefaultInput
                id="phone"
                value={client.phone}
                label="Telefone *"
                error={client.phone.length === 0 ? 'Campo obrigatório' : ''}
                placeholder="DDD + Número"
                onChange={(arg) => setClient({ ...client, phone: maskPhone(arg) })}
                loading={false}
              />
              {/* {selectedProduct.type === ProductType.ENTERPRISE && (
              <>
                <DefaultInput
                  id="holders"
                  value={client.numberOfHolders.toString()}
                  label="Total titulares *"
                  type="number"
                  error={client.numberOfHolders === 0 ? 'Deverá conter ao menos 1' : ''}
                  placeholder="Total de funcionários"
                  onChange={(arg) => setClient({ ...client, numberOfHolders: Number(fixValue(arg)) })}
                  loading={false}
                />
                <p className="text-muted" style={{ fontSize: 11 }}>*Dados de titulares devem ser informados para um de nossos representantes, após adesão.</p>
              </>
              )}
              <DefaultInput
                id="dependents"
                value={client.numberOfDependents.toString()}
                label="Total dependentes *"
                type="number"
                placeholder="Total de dependentes"
                onChange={(arg) => setClient({ ...client, numberOfDependents: Number(fixValue(arg)) })}
                loading={false}
              /> */}
              {/* <p className="text-muted" style={{ fontSize: 11 }}>*Dados de dependentes devem ser informados para um de nossos representantes, após adesão do titular.</p> */}
            </div>
          </div>
          <div className="col-sm-12 col-lg-4 p-4">
            <div className="title-wrapper">
              <p className="form-title">Endereço</p>
              <span className="material-icons-round">expand_more</span>
            </div>
            <div className="subtitle">
              <p className="text-muted">Preencha os campos abaixo com seu endereço.</p>
            </div>
            <div className="input-container">
              <div className="d-flex">
                <div className="col-6 double-input">
                  <DefaultInput
                    id="cep"
                    type="text"
                    error={client.addressCep.length < 9 ? 'Campo obrigatório' : ''}
                    label="cep *"
                    value={client.addressCep}
                    onChange={(arg) => setClient({ ...client, addressCep: maskCep(arg) })}
                    placeholder="0000-000"
                    loading={fetchCepLoading}
                  />
                </div>
                <div className="col-6 double-input">
                  <DefaultOption
                    id="estado"
                    label="Estado *"
                    value={client.addressState}
                    onChange={(arg) => setClient({ ...client, addressState: arg })}
                    placeholder="SELECIONE"
                    loading={fetchCepLoading}
                    elements={[...statesOfBrazil]}
                  />
                </div>
              </div>
              <DefaultInput
                id="city"
                label="cidade *"
                type="text"
                value={client.addressCity}
                error={client.addressCity === '' ? 'Campo obrigatório' : ''}
                onChange={(arg) => setClient({ ...client, addressCity: arg })}
                placeholder="Ex: Imperatriz"
                loading={fetchCepLoading}
              />
              <DefaultInput
                id="street"
                label="Logradouro *"
                type="text"
                value={client.addressStreet}
                error={client.addressStreet === '' ? 'Campo obrigatório' : ''}
                onChange={(arg) => setClient({ ...client, addressStreet: arg })}
                placeholder="Ex: Av. Alameda dos anjos"
                loading={fetchCepLoading}
              />
              <DefaultInput
                id="neighborhood"
                label="Bairro *"
                type="text"
                value={client.addressNeighborhood}
                error={client.addressNeighborhood === '' ? 'Campo obrigatório' : ''}
                onChange={(arg) => setClient({ ...client, addressNeighborhood: arg })}
                placeholder="Digite o bairro"
                loading={fetchCepLoading}
              />
              <div className="d-flex">
                <div className="col-6 double-input">
                  <DefaultInput
                    id="number"
                    label="Número *"
                    error={client.addressNumber === '' ? 'Campo obrigatório' : ''}
                    type="number"
                    placeholder="Ex: 00"
                    value={client.addressNumber}
                    onChange={(arg) => setClient({ ...client, addressNumber: arg })}
                    loading={false}
                  />
                </div>
                <div className="col-6 double-input">
                  <DefaultInput
                    id="complement"
                    type="text"
                    label="Complemento"
                    value={client.addressComplement}
                    placeholder="Ex: Casa A"
                    onChange={(arg) => setClient({ ...client, addressComplement: arg })}
                    loading={false}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-lg-4 p-4">
            <div className="title-wrapper">
              <p className="form-title">Pagamento</p>
              <span className="material-icons-round">expand_more</span>
            </div>
            <div className="subtitle">
              <p className="text-muted">Selecione o método de pagamento.</p>
            </div>
            <div className="input-container">
              <DefaultOption
                id="payment-method"
                label="Método de pagamento *"
                onChange={(method: any) => {
                  if (method === 'CARTÃO DE CRÉDITO - 12 X') {
                    setPaymentMethod('CARTÃO');
                  }
                  if (method === 'CARNÊ BOLETO/PIX - 12 X') {
                    setPaymentMethod('BOLETO-12X');
                  }
                  if (method === 'CARNÊ BOLETO/PIX - 3 X') {
                    setPaymentMethod('BOLETO-3X');
                  }
                  if (method === 'CARNÊ BOLETO/PIX - 6 X') {
                    setPaymentMethod('BOLETO-6X');
                  }
                  if (method === 'PIX - A VISTA') {
                    setPaymentMethod('PIX');
                  }
                }}
                value={resolvePaymentMethod()}
                loading={false}
                // elements={['CARTÃO DE CRÉDITO - 12 X', 'CARNÊ BOLETO/PIX - 3 X', 'PIX - A VISTA']}
                elements={['CARTÃO DE CRÉDITO - 12 X', 'CARNÊ BOLETO/PIX - 6 X', 'PIX - A VISTA']}
              // elements={['CARTÃO DE CRÉDITO - 12 X']}
              />
              <div className="total-value mt-4">
                {paymentMethod !== 'PIX' && (
                  <>
                    {paymentMethod === 'BOLETO-3X' && (
                      <p>
                        3X R$
                        {Number(Number(calcValue().toFixed(2)) / 3).toFixed(2)}
                      </p>
                    )}
                    {paymentMethod === 'BOLETO-6X' && (
                      <p>
                        6X R$
                        {Number(Number(calcValue().toFixed(2)) / 6).toFixed(2)}
                      </p>
                    )}
                    {paymentMethod === 'BOLETO-12X' && (
                      <p>
                        12X R$
                        {Number(Number(calcValue().toFixed(2)) / 12).toFixed(2)}
                      </p>
                    )}
                    {paymentMethod === 'CARTÃO' && (
                      <p>
                        12X R$
                        {Number(Number(calcValue().toFixed(2)) / 12).toFixed(2)}
                      </p>
                    )}
                  </>
                )}
                {/* <p style={{ fontSize: '8px' }}>
                  CONTRATO ANUAL R$
                  {calcValue().toFixed(2)}
                </p> */}
                <p style={{ fontSize: '8px' }}>
                  contrato anual
                </p>
                {paymentMethod === 'BOLETO-6X' && (
                <div style={{
                  border: '1px solid #0073e6', padding: '20px', textAlign: 'center', marginTop: '20px',
                }}
                >
                  <p style={{ fontWeight: 'bold', fontSize: '16px', color: '#000' }}>
                    Atenção
                  </p>
                  <p style={{ fontSize: '14px', color: '#555' }}>
                    Para pagamentos via boleto utilizando o código de barras, o banco pode levar até
                    {' '}
                    <strong>72 horas</strong>
                    {' '}
                    para processar o reconhecimento.
                  </p>
                  <p style={{ fontSize: '14px', color: '#555' }}>
                    Para maior agilidade, recomendamos realizar o pagamento de boleto utilizando o
                    {' '}
                    <strong>QR Code</strong>
                    .
                  </p>
                </div>
                )}
                {paymentMethod === 'PIX' && (
                  <p>
                    R$
                    {' '}
                    {calcValue().toFixed(2)}
                  </p>
                )}
              </div>
              {paymentMethod === 'CARTÃO' && (
                <>
                  <div className="mt-4">
                    <Cards
                      cvc={paymentMethodCard.cardCvv}
                      expiry={paymentMethodCard.cardExpiration}
                      name={paymentMethodCard.cardHolder}
                      number={paymentMethodCard.cardNumber}
                      focused={focusCvv ? 'cvc' : 'number'}
                      placeholders={{ name: 'SEU NOME AQUI' }}
                      locale={{ valid: 'Válido até' }}
                    />
                  </div>
                  <DefaultInput
                    id="number-card"
                    value={paymentMethodCard.cardNumber}
                    error={paymentMethodCard.cardNumber === '' ? 'Campo obrigatório' : ''}
                    label="Número do cartão *"
                    type="number"
                    placeholder="Ex: 0000 0000 0000 0000"
                    onChange={(arg) => setPaymentMethodcard({ ...paymentMethodCard, cardNumber: arg })}
                    loading={false}
                  />
                  <DefaultInput
                    id="titular"
                    label="Titular *"
                    value={paymentMethodCard.cardHolder}
                    error={paymentMethodCard.cardHolder === '' ? 'Campo obrigatório' : ''}
                    placeholder="Ex: Jackson Douglas"
                    onChange={(arg) => setPaymentMethodcard({ ...paymentMethodCard, cardHolder: arg })}
                    loading={false}
                  />
                  <div className="d-flex">
                    <div className="col-6 double-input">
                      <DefaultInput
                        id="validade"
                        label="Validade *"
                        value={paymentMethodCard.cardExpiration}
                        error={
                          paymentMethodCard.cardExpiration.length !== 7 ? 'Campo obrigatório' : ''
                        }
                        placeholder="10/2023"
                        onChange={(arg) => setPaymentMethodcard({
                          ...paymentMethodCard,
                          cardExpiration: maskCardExpiration(arg),
                        })}
                        loading={false}
                      />
                    </div>
                    <div className="col-6 double-input">
                      <DefaultInput
                        id="cvv"
                        value={paymentMethodCard.cardCvv}
                        label="CVV *"
                        error={paymentMethodCard.cardCvv === '' ? 'Campo obrigatório' : ''}
                        placeholder="Ex: 532"
                        onChange={(arg) => setPaymentMethodcard({ ...paymentMethodCard, cardCvv: arg })}
                        loading={false}
                        focusIn={() => setFocusCvv(true)}
                        focusOut={() => setFocusCvv(false)}
                      />
                    </div>
                  </div>
                </>
              )}

              <div className="col-12 mt-4">
                <div className="terms-wrapper d-flex align-items-center p-2 border ">
                  <input
                    type="checkbox"
                    checked={terms.termsOfUse}
                    onChange={() => setTerms({ ...terms, termsOfUse: !terms.termsOfUse })}
                  />
                  <p className="px-2 m-0">
                    Declaro que li e aceito os
                    {' '}
                    <a target="_blank" href="https://redesanta-public.s3.us-east-1.amazonaws.com/Contrato-RedeSanta-2024.pdf" className="fw-bold text-decoration-underline" rel="noreferrer">
                      <button
                        type="button"
                        className="clean-btn"
                      // data-bs-toggle="modal"
                      // data-bs-target="#use-terms-modal"
                      >
                        <span className="fw-bold text-decoration-underline">Termos de Uso</span>
                      </button>
                    </a>
                  </p>
                </div>
                {/* <div className="terms-wrapper d-flex d-flex align-items-center mt-2">
                  <input type="checkbox" checked={terms.termsOfPolicy} onChange={() => setTerms({ ...terms, termsOfPolicy: !terms.termsOfPolicy })} />
                  <p className="px-2 m-0">
                    Declaro que li e aceito a
                    {' '}
                    <span className="fw-bold text-decoration-underline">Política de Privacidade</span>
                  </p>
                </div> */}
                <div className="w-100">
                  <button
                    type="button"
                    className="cta-btn-lead"
                    onClick={() => sendForm()}
                    data-bs-toggle="modal"
                    data-bs-target="#modalResponse"
                  >
                    Quero Aderir
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="row mt-4">
          <div className="terms-wrapper d-flex align-items-center">
            <input type="checkbox" checked={terms.termsOfUse} onChange={() => setTerms({ ...terms, termsOfUse: !terms.termsOfUse })} />
            <p className="px-2 m-0">
              Declaro que li e aceito os
              {' '}
              <span className="fw-bold text-decoration-underline">Termos de Uso</span>
            </p>
          </div>
          <div className="terms-wrapper d-flex d-flex align-items-center mt-2">
            <input type="checkbox" checked={terms.termsOfPolicy} onChange={() => setTerms({ ...terms, termsOfPolicy: !terms.termsOfPolicy })} />
            <p className="px-2 m-0">
              Declaro que li e aceito a
              {' '}
              <span className="fw-bold text-decoration-underline">Política de Privacidade</span>
            </p>
          </div>
        </div> */}
        {/* <div className="row mt-4 mb-4">
          <button type="button" className="cta-btn-lead" onClick={() => sendForm()} data-bs-toggle="modal" data-bs-target="#modalResponse">Quero Aderir</button>
        </div> */}
      </div>
      <ModalResponse id="modalResponse" loading element={modalChild} />
      <ModalTerms id="use-terms-modal" />
    </>
  );
}
